
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getComponent, randomAssetNameForDemo } from '@/utils/helpers';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Component({
  components: {
    TasqHistory: () => getComponent('tasqs/TasqHistory'),
  },
})
export default class TasqWellDetails extends Vue {
  @Prop({ required: true }) tasq!: TasqJob;

  HEADER_TAB = 'Data entry';

  randomAssetNameForDemo = randomAssetNameForDemo;

  HISTORY_TAB = 'History';

  activeTab = this.HISTORY_TAB;

  clickTab(name): void {
    this.activeTab = name;
  }

 get activeTasq(){
  return tasqsListModule.activeTasq
 }

  get datalist() {
    return [
      {
        title: 'Pad Name',
        value: this.activeTasq ? this.randomAssetNameForDemo('Pad',this.activeTasq.padName):  this.randomAssetNameForDemo('Pad',this.tasq.padName),
      },
      {
        title: 'Route',
        value: this.activeTasq ? this.randomAssetNameForDemo('Route',this.activeTasq.route) : this.randomAssetNameForDemo('Route',this.tasq.route),
      },
      {
        title: 'Area',
        value: this.activeTasq ? this.randomAssetNameForDemo('Area',this.activeTasq.area) : this.randomAssetNameForDemo('Area',this.tasq.area),
      },
      {
        title: 'Team',
        value: this.activeTasq ? this.randomAssetNameForDemo('Team', this.activeTasq.team)  : this.randomAssetNameForDemo('Team', this.tasq.team),
      },
    ];
  }
}
